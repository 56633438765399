<!-- <div nz-row nzJustify="space-around" nzAlign="middle" class="container">
    <div nz-col nzXs="22" nzSm="20" nzMd="16" nzLg="12" nzXl="8">

    </div>
</div> -->
<nz-card>
    <nz-result nzStatus="403" nzTitle="การเข้าถึงถูกปฏิเสธ" nzSubTitle="มีบางอย่างผิดพลาด ต้องขออภัยเป็นอย่างสูง">
        <div nz-result-extra>
            <button nz-button nzType="primary" (click)="closeWindow()">ปิดหน้าต่างนี้</button>
        </div>
    </nz-result>
</nz-card>