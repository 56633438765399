import { Injectable } from '@angular/core';
import { ApiService, Result } from 'src/app/core/services/api.service';
import { HttpParams } from '@angular/common/http';

export const MissionOption = [
    { label: 'เข้าใช้งานระบบต่อเนื่อง', value: 'sign_in' },
    { label: 'ตอบคำถามสุขภาพต่อเนื่อง', value: 'screening' },
    { label: 'เป็นสมาชิกของ', value: 'member_of' },
    { label: 'เช็คอินสถานที่', value: 'check_in_tenant' }
];

export interface Mission {
    type: string;
    count: number;
    uid: string;
    name: string;
}

export interface QuestMission {
    uid: string;
    reward: string;
    reward_value: number;
    missions: Array<Mission>;
    coupon: QuestCoupon;
}

export interface QuestCoupon {
    account_id: string;
    quest_uid: string;
    valid_until: Date;
    used_at: Date;
    valid_coupon: boolean;
    description: string;
    e_coupon: boolean;
}

export interface EventPromotion {
    uid: string;
    title: string;
    description: string;
    start: Date;
    end: Date;
    quest: Array<QuestMission>;
    img: string;
    rating: number;
    rating_count: number;
    deactivated: boolean;
}


@Injectable({ providedIn: 'root' })
export class EventService {
    constructor(
        private apiService: ApiService,
    ) { }

    getPromotion = async (feed: boolean = false): Promise<Result> => {
        return this.apiService.get(`/v1/event`, new HttpParams().append(`feed`, `${feed}`)).toPromise();
    }

    getPromotionImg = async (eventUid: string): Promise<Result> => {
        return this.apiService.get(`/v1/event/${eventUid}/image`).toPromise();
    }

    ackPromotion = async (eventUid: string): Promise<Result> => {
        return this.apiService.post(`/v1/event/${eventUid}`, null).toPromise();
    }

    votePromotion = async (eventUid: string, rating: number): Promise<Result> => {
        return this.apiService.post(`/v1/event/${eventUid}/rating`, { rating }).toPromise();
    }

    getMyQuestCoupon = async (eventUid: string, questUid: string): Promise<Result> => {
        return this.apiService.get(`/v1/event/${eventUid}/quest/${questUid}`).toPromise();
    }

    useQuestCoupon = async (eventUid: string, questUid: string): Promise<Result> => {
        return this.apiService.post(`/v1/event/${eventUid}/quest/${questUid}/coupon`, null).toPromise();
    }

    collectQuestCoupon = async (eventUid: string, questUid: string): Promise<Result> => {
        return this.apiService.post(`/v1/event/${eventUid}/quest/${questUid}/coupon/collect`, null).toPromise();
    }
}
