import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorComponent } from './core/components/error/error.component';
import { Err404 } from './core/components/error/error.model';

import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
    { path: '', redirectTo: 'auth', pathMatch: 'full' },
    { path: 'approve', loadChildren: () => import('./features/approve/approve.module').then(m => m.ApproveModule) },
    { path: 'auth', loadChildren: () => import('./features/auth/auth.module').then(m => m.AuthModule) },
    { path: 'home', loadChildren: () => import('./features/home/home.module').then(m => m.HomeModule), canActivate: [AuthGuard] },
    // { path: 'notification', loadChildren: () => import('./features/notification/notification.module').then(m => m.NotificationModule), canActivate: [AuthGuard] },
    { path: 'setting', loadChildren: () => import('./features/setting/setting.module').then(m => m.SettingModule), canActivate: [AuthGuard] },
    // { path: 'support', loadChildren: () => import('./features/support/support.module').then(m => m.SupportModule), canActivate: [AuthGuard] },
    // { path: 'reward', loadChildren: () => import('./features/reward/reward.module').then(m => m.RewardModule), canActivate: [AuthGuard] },
    // { path: 'friend', loadChildren: () => import('./features/friend/friend.module').then(m => m.FriendModule), canActivate: [AuthGuard] },
    { path: 'personal', loadChildren: () => import('./features/personal/personal.module').then(m => m.PersonalModule), canActivate: [AuthGuard] },
    // { path: 'feedback', loadChildren: () => import('./features/feedback/feedback.module').then(m => m.FeedbackModule), canActivate: [AuthGuard] },
    { path: 'promotion', loadChildren: () => import('./features/promotion/promotion.module').then(m => m.PromotionModule), canActivate: [AuthGuard] },
    { path: 'error', component: ErrorComponent, data: Err404 },
    { path: '**', redirectTo: 'error', pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
