// This file was generate from script, You should not commit or upload this file to public
// It was contain many system credential for build only
export const config = {
    production: false,
    app_name: 'COVID-19 Tracking Platform',
    app_version: 'v1.4.0-rc.70',
    build_time: 'Tue Nov 23 07:21:11 UTC 2021',
    build_commit: 'c604050',
    google_api_key: 'AIzaSyCsvQ62z2GFWU9Uvk78ttKCJGDY2mZAMZs',
};
