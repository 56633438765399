import { Injectable } from '@angular/core';
import { ApiService, Result } from 'src/app/core/services/api.service';
import { generateSalt, encryptSecret } from '../operators/util.operator';
import { HttpParams } from '@angular/common/http';
import { tap } from 'rxjs/operators';

export interface LoginTicket {
    expires_at: string;
    ticket: string;
}

export interface VerifiedSignInResult {
    access_token: string;
    refresh_token: string;
    token_type: string;
}

export interface AccountProfile {
    account_id: string;
    created_at: Date;
    name: string;
    telephone: string;
    email: string;
    loa: string;
    avatar: string;
}

@Injectable({ providedIn: 'root' })
export class AuthService {
    constructor(
        private apiService: ApiService,
    ) { }

    private verify = async (token: string, isOneChat = false): Promise<Result> => {
        const salt = generateSalt(32);
        const params = new HttpParams().append('salt', `${salt}`);
        const r = await this.apiService
            .get(`/v1/auth/ticket`, params)
            .toPromise()
            .then((result) => result.data);
        const secret = encryptSecret(token, salt);
        const data = {
            secret,
            ticket: (r as LoginTicket).ticket,
            onechat: isOneChat,
        } as object;
        return this.apiService
            .post(`/v1/auth/signin`, data)
            .pipe(
                tap(
                    (result) => {
                        const verified = result.data as VerifiedSignInResult;
                        localStorage.setItem('access_token', verified.access_token);
                        localStorage.setItem('refresh_token', verified.refresh_token);
                        localStorage.setItem('token_type', verified.token_type);
                        localStorage.setItem('salt', salt);
                    },
                    () => {
                        localStorage.clear();
                    }
                )
            )
            .toPromise();
    }

    getSignInUrl = (): string => `/api/v1/auth/signin`;

    verifyByOneIdAuthorizedCode = async (code: string): Promise<Result> => {
        return this.verify(code, false);
    }

    verifyByOneChatToken = async (token: string): Promise<Result> => {
        return this.verify(token, true);
    }

    isAuthorizedAccess = async (): Promise<boolean> => {
        return this.apiService
            .get('/v1/auth')
            .toPromise()
            .then(() => true)
            .catch((err) => {
                localStorage.clear();
                return false;
            });
    }

    getProfile = async (): Promise<Result> => {
        return this.apiService.get(`/v1/auth/profile`).toPromise();
    }

    getAvartarUrl = async (): Promise<Result> => {
        return this.apiService.get(`/v1/auth/profile/avatar`).toPromise();
    }
}
