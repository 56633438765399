import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService, AccountProfile } from 'src/app/shared/services/auth.service';
import { config } from 'src/environments/config';
import { EventService, EventPromotion } from 'src/app/features/promotion/_services/event.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
    version = config.app_version;
    profile: AccountProfile | undefined;
    showGotoBackButton = false;
    routerSub$: Subscription | undefined;
    routerLinkTitle = '';

    // Notify
    notifyCount = 1;

    // Drawer
    slideManuCollapsed = false;

    // Event
    event: EventPromotion = {} as EventPromotion;
    newEventVisible = false;

    constructor(
        private authService: AuthService,
        private eventService: EventService,
        private router: Router,
    ) {
        this.routerSub$ = this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                if (!['/', '/auth', '/home'].includes(val.url)) {
                    this.showGotoBackButton = true;
                    switch (val.url) {
                        case '/personal':
                            this.routerLinkTitle = 'ข้อมูลส่วนตัว';
                            break;
                        case '/promotion':
                            this.routerLinkTitle = 'โปรโมชันและกิจกรรม';
                            break;
                        case '/reward':
                            this.routerLinkTitle = 'คูปองของฉัน';
                            break;
                        case '/friend':
                            this.routerLinkTitle = 'ชวนเพื่อนมาใช้งาน';
                            break;
                        case '/setting':
                            this.routerLinkTitle = 'กาาตั้งค่าส่วนตัว';
                            break;
                        case '/support':
                            this.routerLinkTitle = 'ช่วยเหลือ';
                            break;
                        case '/feedback':
                            this.routerLinkTitle = 'แจ้งปัญหาใช้งาน';
                            break;
                        case '/notification':
                            this.routerLinkTitle = 'การแจ้งเตือนของฉัน';
                            break;
                    }
                } else {
                    this.showGotoBackButton = false;
                }

                if (!['/', '/auth'].includes(val.url)) {
                    this.authService.getProfile().then((r) => {
                        if (r.data) {
                            this.profile = r.data as AccountProfile;
                        }
                    }
                    ).catch((err) => {
                        console.log(`Get profile error`, err);
                    });
                }
            }
        });
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        if (this.routerSub$) {
            this.routerSub$.unsubscribe();
        }
    }

    gotoHomeButton = (): void => {
        this.router.navigateByUrl('/home');
    }


    closeDrawer = (): void => {
        this.slideManuCollapsed = false;
    }


    loadEventPromotion = (): void => {
        this.eventService.getPromotion().then((r) => {
            if (r && r.data) {
                this.event = r.data as EventPromotion;

                // Popup modal
                this.newEventVisible = true;
            }
        });
    }

    acknowledgeEventPromotion = (): void => {
        if (this.event && this.event.uid) {
            this.eventService.ackPromotion(this.event.uid).then()
                .finally(() => {
                    this.event = {} as EventPromotion;
                    this.newEventVisible = false;
                });
        }
    }

    rateThisPromotion = (event: EventPromotion): void => {
        if (this.event && this.event.uid) {
            this.eventService.votePromotion(this.event.uid, this.event.rating).then().finally(() => {
                this.event = {} as EventPromotion;
                this.newEventVisible = false;
            });
        }
    }

}
