<div class="header">
    <nz-drawer [nzClosable]="false" [nzVisible]="slideManuCollapsed" [nzPlacement]="'right'"
        (nzOnClose)="slideManuCollapsed = !slideManuCollapsed" [nzBodyStyle]="{'padding':'0px'}">
        <ng-template nzDrawerContent>

            <div class="profile-box" *ngIf="profile">
                <div nz-row nzAlign="top">
                    <div nz-col nzFlex="60px">
                        <nz-avatar nzShape="square" nzSrc="{{ profile.avatar }}" [nzSize]="48" nzIcon="user">
                        </nz-avatar>
                    </div>
                    <div nz-col nzFlex="auto" style="text-align: left;">
                        <div class="tbl-title">{{ profile.name }}</div>
                        <div class="tbl-description">
                            {{ profile.email }}
                        </div>
                    </div>
                </div>
            </div>
            <ul nz-menu nzTheme="light" nzMode="inline">
                <li nz-menu-group nzTitle="บัญชีของฉัน">
                    <ul>
                        <li nz-menu-item [nzMatchRouter]="true" routerLink="/home" class="tbl-content"
                            (click)="closeDrawer()">
                            <i nz-icon nzType="home" nzTheme="outline" style="margin-right: 8px;"></i>
                            หน้าหลัก
                        </li>
                        <li nz-menu-item [nzMatchRouter]="true" routerLink="/personal" class="tbl-content"
                            (click)="closeDrawer()">
                            <i nz-icon nzType="idcard" nzTheme="outline" style="margin-right: 8px;"></i>
                            ข้อมูลส่วนตัว
                        </li>
                        <li nz-menu-item [nzMatchRouter]="true" routerLink="/promotion" class="tbl-content"
                            (click)="closeDrawer()">
                            <i nz-icon nzType="tags" nzTheme="outline" style="margin-right: 8px;"></i>
                            โปรโมชันและกิจกรรม
                        </li>
                        <!-- <li nz-menu-item [nzMatchRouter]="true" routerLink="/reward" class="tbl-content"
                            (click)="closeDrawer()">
                            <i nz-icon nzType="tags" nzTheme="outline" style="margin-right: 8px;"></i>
                            คูปองของฉัน
                        </li> -->
                        <!-- <li nz-menu-item [nzMatchRouter]="true" routerLink="/friend" class="tbl-content"
                            (click)="closeDrawer()">
                            <i nz-icon nzType="team" nzTheme="outline" style="margin-right: 8px;"></i>
                            ชวนเพื่อน
                        </li> -->
                    </ul>
                </li>
                <li nz-menu-group nzTitle="การตั้งค่า">
                    <ul>
                        <li nz-menu-item [nzMatchRouter]="true" routerLink="/setting" class="tbl-content"
                            (click)="closeDrawer()">
                            <i nz-icon nzType="setting" nzTheme="outline" style="margin-right: 8px;"></i>ตั้งค่าส่วนตัว
                        </li>
                        <!-- <li nz-menu-item [nzMatchRouter]="true" routerLink="/support" class="tbl-content"
                            (click)="closeDrawer()">
                            <i nz-icon nzType="question-circle" nzTheme="outline"
                                style="margin-right: 8px;"></i>ช่วยเหลือ
                        </li> -->
                        <!-- <li nz-menu-item [nzMatchRouter]="true" routerLink="/feedback" class="tbl-content"
                            (click)="closeDrawer()">
                            <i nz-icon nzType="comment" nzTheme="outline" style="margin-right: 8px;"></i>แจ้งปัญหา
                        </li> -->
                        <!-- <li nz-menu-item [nzMatchRouter]="true" routerLink="/privacy" class="tbl-content">
                            <i nz-icon nzType="safety" nzTheme="outline" style="margin-right: 8px;"></i>ความเป็นส่วนตัว
                        </li> -->
                    </ul>
                </li>
                <!-- <li nz-menu-group nzTitle="สนับสนุน">
                    <ul>
                        <li nz-menu-item [nzMatchRouter]="true" routerLink="/about" class="tbl-content">
                            <i nz-icon nzType="info-circle" nzTheme="outline" style="margin-right: 8px;"></i>เกี่ยวกับ
                        </li>
                        <li nz-menu-item [nzMatchRouter]="true" routerLink="/help" class="tbl-content">
                            <i nz-icon nzType="question-circle" nzTheme="outline" style="margin-right: 8px;"></i>ช่วยเหลือ
                        </li>
                        <li nz-menu-item [nzMatchRouter]="true" routerLink="/feedback" class="tbl-content">
                            <i nz-icon nzType="comment" nzTheme="outline" style="margin-right: 8px;"></i>แจ้งปัญหา
                        </li>
                    </ul>
                </li> -->
            </ul>
            <nz-divider style="margin: 16px 0;"></nz-divider>
            <div class="tbl-description" style="text-align: center; margin-top: 24px;">Covid Tracking Plaform<br />{{
                version }}
            </div>

        </ng-template>
    </nz-drawer>

    <div nz-row nzAlign="middle">
        <div nz-col nzFlex="auto">
            <a routerLink="/">
                <img src="/assets/img/qrcode-header.png" class="header-logo" alt="" />
            </a>
        </div>
        <div nz-col nzFlex="100px" style="text-align: end;" *ngIf="profile">
            <!-- <button nz-button nzType="link" class="header-nav" routerLink="/notification" style="padding: 2.4px 0;">
                <nz-badge style="padding: 0px;" [nzDot]="notifyCount>0"><i nz-icon nzType="bell" nzTheme="outline"
                        routerLinkActive="active-page"></i></nz-badge>
            </button> -->
            <button nz-button nzType="link" class="header-nav" (click)="slideManuCollapsed = !slideManuCollapsed">
                <i nz-icon nzType="menu" nzTheme="outline"></i>
            </button>
        </div>
    </div>

</div>

<div *ngIf="showGotoBackButton" style="background-color: #fff;">
    <button nz-button nzType="link" class="header-nav" (click)="gotoHomeButton()" style="margin-left: 8px;">
        <i nz-icon nzType="arrow-left" nzTheme="outline"></i>
    </button>
    <span style="font-weight: 600;">{{ routerLinkTitle }}</span>
</div>

<!-- 
<nz-modal [(nzVisible)]="newEventVisible" (nzOnCancel)="acknowledgeEventPromotion()"
    [nzBodyStyle]="{'padding':'8px', 'min-height': '350px'}" [nzTitle]="event.title" [nzContent]="registerContent"
    [nzFooter]="registerFooter" [nzStyle]="{'top':'0px', 'min-height':'calc(100vh - 16px)', 'padding-bottom':'0px'}"
    [nzClosable]="false">
    <ng-template #registerContent>
        <div *ngIf="event">
            <img [src]="event.img" width="100%" />
            <div class="tbl-title" style="white-space: pre-wrap; margin: 8px 0;">
                {{ event.description }}
            </div>
            <div class="tbl-content" style="padding: 16px;">
                <div><strong>เริ่ม: </strong>{{ event.start | date: 'dd-MMM-yyyy HH:mm' }}</div>
                <div style="margin-bottom: 16px;"><strong>สิ้นสุด: </strong>{{ event.end | date: 'dd-MMM-yyyy HH:mm' }}
                </div>
                <div *ngFor="let q of event.quest; let i = index" style="margin-bottom: 8px;">
                    <div class="tbl-title primary-text" style="margin-bottom: 8px;">ภารกิจที่ {{ i+1 }}
                        ลุ้นรับโชค {{ q.reward }}</div>
                    <nz-card style="width: 100%; margin-bottom: 8px;" *ngFor="let m of q.missions"
                        [nzBodyStyle]="{'padding': '8px'}">
                        <div class="tbl-content">
                            <i nz-icon nzType="caret-right" nzTheme="outline"></i> {{ m.type | mission }}
                            <span *ngIf="m.type=='sign_in' || m.type=='screening'; else tenantSelectTmpl">{{
                                m.count }}
                                วัน</span>
                            <ng-template #tenantSelectTmpl>{{ m.name }}</ng-template>
                        </div>
                    </nz-card>
                </div>
                <div style="margin-top: 8px;">
                    <nz-divider style="margin: 8px 0;"></nz-divider>
                    <span class="primary-text">โหวตให้คะแนน:</span>
                    <nz-rate [(ngModel)]="event.rating" style="margin-left: 8px; margin-right: 8px;"
                        [nzDisabled]="event.rating>0" [nzTooltips]="feedRatingTooltips"
                        (ngModelChange)="rateThisPromotion(event)">
                    </nz-rate>
                    <span *ngIf="event.rating>0" class="ant-rate-text tbl-content">{{ event.rating ?
                        feedRatingTooltips[event.rating
                        - 1] : ''
                        }}</span>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template #registerFooter>
        <button nz-button nzType="default" nzDanger nzBlock (click)="acknowledgeEventPromotion()"><i nz-icon
                nzType="close"></i>
            ปิดหน้าต่างนี้</button>
    </ng-template>
</nz-modal> -->