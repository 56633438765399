import { NgModule } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Core import
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// Locale
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
registerLocaleData(en);

// Core component
import { BodyComponent } from './core/layout/body/body.component';
import { FooterComponent } from './core/layout/footer/footer.component';
import { ErrorComponent } from './core/components/error/error.component';
import { HeaderComponent } from './core/layout/header/header.component';

// Core layout module
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';

/** Http interceptor providers in outside-in order */
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenInterceptor } from './core/interceptors/auth.interceptor';

export const httpInterceptorProviders = [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthenInterceptor,
        multi: true
    },
];

@NgModule({
    declarations: [
        AppComponent,
        BodyComponent,
        FooterComponent,
        ErrorComponent,
        HeaderComponent,
    ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        // NgZorro for layout
        NzLayoutModule,
        NzGridModule,
        NzResultModule,
        NzCardModule,
        NzButtonModule,
        NzBadgeModule,
        NzDividerModule,
        NzDrawerModule,
        NzListModule,
        NzMenuModule,
        NzIconModule,
        NzAvatarModule,
    ],
    providers: [
        { provide: NZ_I18N, useValue: en_US },
        httpInterceptorProviders,
        Title,

    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
